<template>
  <div>
    <div class="content-header container-fluid bg-white">
      <div class="row">
        <div class="col-sm-4">
          <h4><i class="ti-direction-alt"></i> 工作流转 | 业务首页</h4>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12 wfl_lis">
          <ul class="icon-list">
            <li @click="goto('d')">
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
            <li>
                <span>
                  <i class="el-icon-platform-eleme"/>
                  <span class="icon-name">物料申领</span>
                </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      ls:[]
    }
  },
  created() {
  },
  methods:{
    getConfig(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/School/WFL/WConfigApi/GetList",
        completed(its){
          self.ls=its;
        }
      })
    },
    goto(url){
      console.log("A",url)
    }
  }
}
</script>

<style>
  .wfl_lis{padding: 20px 200px}
  .wfl_lis ul.icon-list {
    overflow: hidden;
    list-style: none;
    padding: 0!important;
    border: 1px solid #eaeefb;
    border-radius: 4px;
  }
  .wfl_lis ul:not(.timeline) {
    margin: 10px 0;
    padding: 0 0 0 20px;
    font-size: 14px;
    color: #5e6d82;
    line-height: 2em;
  }
  .wfl_lis .icon-list li {
    float: left;
    width: 150px;
    text-align: center;
    height: 120px;
    line-height: 120px;
    color: #666;
    font-size: 13px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
  }
  .wfl_lis .icon-list li i {
    display: block;
    font-size: 50px;
    margin-bottom: 15px;
    color: #606266;
    transition: color .15s linear;
  }
  .wfl_lis .icon-list li span {
    line-height: normal;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    color: #99a9bf;
    transition: color .15s linear;
  }
  .wfl_lis .icon-list li .icon-name {
    display: inline-block;
    padding: 0 3px;
    height: 1em;
  }
  .wfl_lis .icon-list li:after, .icon-list li span {
    display: inline-block;
    vertical-align: middle;
  }
</style>